<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div>
        <el-input placeholder="请输入订单号" v-model="orderId"></el-input>
        <el-select v-model="status" placeholder="请选择订单状态">
          <el-option
            v-for="item in optionsStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select v-model="city" placeholder="请选择活动城市">
          <el-option
            v-for="item in optionsCity"
            :key="item.id"
            :label="item.country + '-' + item.city"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.primaryImage"
          label="活动主图"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="
                scope.row.worldActivityOrderResponse.activityDTO.primaryImage
              "
              :preview-src-list="[
                scope.row.worldActivityOrderResponse.activityDTO.primaryImage,
              ]"
              v-if="
                scope.row.worldActivityOrderResponse.activityDTO.primaryImage
              "
            >
            </el-image>
            <div
              v-if="
                !scope.row.worldActivityOrderResponse.activityDTO.primaryImage
              "
            >
              暂无图片
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="activityTitle" label="活动名称" width="180">
          <template slot-scope="scope">
            <div>
              中文：{{
                scope.row.worldActivityOrderResponse.activityDTO.activityTitle
              }}
            </div>
            <div
              v-if="
                scope.row.worldActivityOrderResponse.activityDTO.activityTitleUs
              "
            >
              英文：{{
                scope.row.worldActivityOrderResponse.activityDTO.activityTitleUs
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="payExamineVO.phone"
          label="账号"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.activityPrice"
          label="单价"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.activityDTO.activityStock"
          label="库存"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column label="实收款" width="140" align="center">
          <template slot-scope="scope">
            $
            {{
              scope.row.worldActivityOrderResponse.payOrder.payType == 1
                ? scope.row.worldActivityOrderResponse.payOrder.amount
                : scope.row.worldActivityOrderResponse.payOrder.payType == 7
                ? scope.row.worldActivityOrderResponse.payOrder.amountUsdt
                : scope.row.worldActivityOrderResponse.payOrder.payType == 8
                ? scope.row.worldActivityOrderResponse.payOrder.amountToken
                : "0"
            }}<br />
          </template>
        </el-table-column>
        <el-table-column
          prop="payExamineVO.wallet"
          label="钱包地址"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="payExamineVO.orderId" label="订单号" width="130">
        </el-table-column>
        <el-table-column
          prop="payExamineVO.payTime"
          label="下单时间"
          width="170"
        >
        </el-table-column>
        <el-table-column
          prop="worldActivityOrderResponse.payOrder.orderStatus"
          label="订单状态"
          width="170"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.worldActivityOrderResponse.bizOrder.orderStatus }}
            </div>
            <el-button
              size="mini"
              type="text"
              @click="handleOrderDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { format_time_date } from "@/util/funBusiness";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "活动管理",
        },
        {
          name: "订单列表",
        },
      ], // 面包屑数据

      optionsCity: [],
      city: "",
      orderId: "",
      date: "",
      status: "",
      optionsStatus: [
        {
          value: "1",
          label: "未支付",
        },
        {
          value: "2",
          label: "支付审核中",
        },
        {
          value: "5",
          label: "用户取消",
        },
        {
          value: "6",
          label: "平台取消",
        },
        {
          value: "7",
          label: "订单确认",
        },
      ],

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldActivityOrderListOrder,
      method: "POST",
      params: JSON.stringify({
        activityId: "",
        orderId: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          this.tableData.map((item) => {
            this.changeItem(item);
          });

          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);

    const optCity = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsCity = res.data.data.list;
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optCity);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldActivityOrderListOrder,
        method: "POST",
        params: JSON.stringify({
          cityId: this.city,
          orderId: this.orderId,
          startTime: this.date[0],
          endTime: this.date[1],
          WorldOrderStatus: this.status * 1,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            this.tableData.map((item) => {
              this.changeItem(item);
            });

            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handleOrderDetail(row) {
      if (row.payExamineVO != null) {
        this.$router.push({
          path: "/worldActivityOrderDetail",
          query: {
            orderId: row.payExamineVO.orderId,
          },
        });
      } else {
        this.$message.error("无法查看详情");
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldActivityOrderListOrder,
        method: "POST",
        params: JSON.stringify({
          cityId: this.city,
          orderId: this.orderId,
          startTime: this.date[0],
          endTime: this.date[1],
          WorldOrderStatus: this.status * 1,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            this.tableData.map((item) => {
              this.changeItem(item);
            });

            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    changeItem(item) {
      let worldResPay = item.payExamineVO;
      let worldResOrder = item.worldActivityOrderResponse.bizOrder;
      let worldResOrderDTO = item.worldActivityOrderResponse.activityDTO;

      if (worldResPay != null) {
        worldResPay.payTime = format_time_date(worldResPay.payTime);
      }

      if (worldResOrder.orderStatus == 1) {
        worldResOrder.orderStatus = "未支付";
      } else if (worldResOrder.orderStatus == 2) {
        worldResOrder.orderStatus = "支付审核中";
      } else if (
        worldResOrder.orderStatus == 3 &&
        (worldResOrderDTO.activityStatus == 2 ||
          worldResOrderDTO.activityStatus == 3)
      ) {
        worldResOrder.orderStatus = "活动已预订，未开始";
      } else if (
        worldResOrder.orderStatus == 3 &&
        worldResOrderDTO.activityStatus == 4
      ) {
        worldResOrder.orderStatus = "活动已预订，进行中";
      } else if (
        worldResOrder.orderStatus == 3 &&
        worldResOrderDTO.activityStatus == 5
      ) {
        worldResOrder.orderStatus = "活动已完成";
      } else if (worldResOrder.orderStatus == 4) {
        worldResOrder.orderStatus = "支付审核拒绝";
      } else if (worldResOrder.orderStatus == 5) {
        worldResOrder.orderStatus = "用户取消";
      } else if (worldResOrder.orderStatus == 6) {
        worldResOrder.orderStatus = "平台取消";
      } else if (worldResOrder.orderStatus == 7) {
        worldResOrder.orderStatus = "已确认";
      }
    },

    reset() {
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";
</style>
